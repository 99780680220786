import React from "react"
import MacOSBadge from "../images/macos-badge.svg"
import WindowsBadge from "../images/windows-badge.svg"
import PlayStoreBadge from "../images/play-store-badge.svg"
import AppStoreBadge from "../images/app-store-badge.svg"
import LinuxBadge from "../images/linux-badge.svg"
import { Grid, Typography } from "@material-ui/core"

const items = [
  {
    link: "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl",
    badge: <WindowsBadge />,
  },
  {
    link: "https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12",
    badge: <MacOSBadge />,
  },
  {
    link: "https://snapcraft.io/blix",
    badge: <LinuxBadge />,
  },
  {
    link: "https://play.google.com/store/apps/details?id=com.onblix.app",
    badge: <PlayStoreBadge />,
  },
  {
    link: "https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1",
    badge: <AppStoreBadge />,
  },
]

const DownloadsSection = ({ data, direction }) => {
  return (
    <div>
      <Typography
        variant="h4"
        align="center"
        style={{ color: "#000", fontWeight: "bold" }}
      >
        {data ? data : ""}
      </Typography>
      <Grid
        spacing={7}
        justify="center"
        alignItems="center"
        direction={direction}
        container
        className="badges-container"
      >
        {items.map((itemp, i) => {
          return (
            <Grid item key={i}>
              <a href={itemp.link} target="_blank" rel="noopener noreferrer">{itemp.badge}</a>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default DownloadsSection